import Link from 'next/link'
import React, { useCallback, useState } from 'react'
import useMediaQuery from '../../utils/publicApi'
// Utils
import styles from './listPets.module.scss'

export default function PetList({ more, less, breedsExplorer }) {
  const isMobile = useMediaQuery('(max-width:600px)')
  const [showAll, setShowAll] = useState(false)
  const combined = [
    ...(breedsExplorer?.withDogs || []),
    ...(breedsExplorer?.withoutDogs || []),
  ]

  const handleSetShow = useCallback(() => {
    setShowAll(!showAll)
  }, [setShowAll, showAll])

  return (
    <div className={styles.article}>
      <div className={styles.articleContent}>
        <p className={styles.title}> Explore by Breed</p>
        <div className={styles.data}>
          {(showAll ? combined : breedsExplorer?.withDogs)?.map(
            ({ id, name, slug, hasDogs }) => (
              <div key={id} className={!hasDogs ? styles.grey : styles.link}>
                <Link href={`/puppies-for-sale/${slug}`} alt={name}>
                  <p>
                    {' '}
                    {isMobile && name.length > 20
                      ? name.slice(0, 20) + '...'
                      : name}{' '}
                  </p>
                </Link>
              </div>
            ),
          )}
        </div>
      </div>
      {combined.length > 20 && (
        <div onClick={handleSetShow} className={styles.view}>
          <p>
            {showAll ? less : more}{' '}
            <span>
              <img
                width="11px"
                height="6px"
                alt={showAll ? 'Black up arrow' : 'Black down arrow'}
                src={
                  showAll
                    ? '/search/arrow-top-black.svg'
                    : '/search/arrow-down-black.svg'
                }
              />
            </span>
          </p>
        </div>
      )}
    </div>
  )
}
