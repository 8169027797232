import cn from 'classnames'
import { memo, useCallback, useEffect, useState } from 'react'
import styles from './allLocations.module.scss'

const BreedCountry = memo(({ dogs, countryName }) => {
  return (
    <>
      {countryName && dogs.length !== 0 && <h3>{countryName}</h3>}
      <div className={styles.breedStates}>
        {dogs.map((dog, index) => (
          <div
            key={index}
            className={dog.count ? styles.match : styles.noMatch}
          >
            <a href={`/puppies-for-sale/${dog.slug}`}>{dog.state}</a>
          </div>
        ))}
      </div>
    </>
  )
})

const sortByCount = (a, b) => b.count - a.count

const RelatedStates = memo(({ breedersLocation, inputText, hasSearch }) => {
  const [showAll, setShowAll] = useState(false)
  const [usa, setUsa] = useState(
    (breedersLocation?.locations?.[0]?.states || []).sort(sortByCount)
  )
  const [canada, setCanada] = useState(
    (breedersLocation?.locations?.[1]?.states || []).sort(sortByCount)
  )

  // Filter states with dogs

  const usaMatch = []

  usa.map((elem) => {
    if (elem.count !== 0) {
      usaMatch.push(elem)
    }
    return usaMatch
  })

  const canadaMatch = []

  canada.map((elem) => {
    if (elem.count !== 0) {
      canadaMatch.push(elem)
    }
    return canadaMatch
  })

  useEffect(() => {
    if ((inputText || '').length > 0 && hasSearch) {
      setUsa(
        breedersLocation?.locations?.[0]?.states
          .sort(sortByCount)
          .filter((state) => state.state.toLowerCase().includes(inputText))
      )
      setCanada(
        breedersLocation?.locations?.[1]?.states
          .sort(sortByCount)
          .filter((state) => state.state.toLowerCase().includes(inputText))
      )
    } else if (!(inputText || '').length > 0 && hasSearch) {
      setUsa(breedersLocation?.locations?.[0]?.states.sort(sortByCount))
      setCanada(breedersLocation?.locations?.[1]?.states.sort(sortByCount))
    }
  }, [
    showAll,
    inputText,
    hasSearch,
    breedersLocation?.locations?.[1],
    breedersLocation?.locations?.[0]
  ])

  const handleSetShow = useCallback(() => {
    setShowAll(!showAll)
  }, [setShowAll, showAll])

  return (
    <div className={styles.relatedStatesContainer}>
      {hasSearch && usa.length === 0 && canada.length === 0 && (
        <div className={styles.noResult}>
          <p>
            We have no locations that match this description, please search
            again!
          </p>
        </div>
      )}
      <BreedCountry
        dogs={showAll || hasSearch ? usa : usaMatch}
        inputText={inputText}
      />
      <BreedCountry
        dogs={showAll || hasSearch ? canada : canadaMatch}
        countryName='Canada'
        inputText={inputText}
      />

      {!hasSearch && (
        <div onClick={handleSetShow} className={styles.view}>
          <p>
            {showAll ? 'View less locations' : 'View all locations'}{' '}
            <span>
              <img
                width='11px'
                height='6px'
                alt={showAll ? 'Black up arrow' : 'Black down arrow'}
                src={
                  showAll
                    ? '/search/arrow-top-black.svg'
                    : '/search/arrow-down-black.svg'
                }
              />
            </span>
          </p>
        </div>
      )}
    </div>
  )
})

export default function AllLocations ({ breedersLocation, hasSearch }) {
  const [inputText, setInputText] = useState('')

  const inputHandler = (e) => {
    // convert input text to lower case
    const lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  return (
    <div className={cn(styles.article, styles.marginBottom)}>
      {hasSearch
        ? (
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}> Explore by Location </h2>
            <input
              type='search'
              placeholder='Search State or Province'
              onChange={inputHandler}
            />
          </div>
          )
        : (
          <p className={styles.title}> Explore by Location </p>
          )}
      {!!breedersLocation && (
        <RelatedStates
          breedersLocation={breedersLocation}
          inputText={inputText}
          hasSearch={hasSearch}
        />
      )}
    </div>
  )
}
