import React from 'react'
import BlogPostCard from './blogPostCard'
import styles from './blogPost.module.scss'

export const BlogPost = ({ data }) => {
  return (
    <div className={styles.card}>
      {data?.map((b, i) => <BlogPostCard data={b} key={i} />).slice(0, 4)}
    </div>
  )
}

export default BlogPost
