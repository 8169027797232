import React from 'react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import styles from './blogBanner.module.scss'
import Button from '../newButton/Button'

const BlogBanner = () => {
  const router = useRouter()
  return (
    <div className={styles.banner__wrapper}>
      <div className={styles.banner}>
        <div className={styles.banner__image}>
          <Image
            width={317}
            height={223}
            src='/post/woof-paper.webp' alt='Black Labrador Retriever reading a newspaper titled Daily Dog'
            layout='responsive'
            objectFit='cover'
            loading='lazy'
          />
        </div>
        <div className={styles.banner__info}>
          <div className={styles.banner__info_wrapper}>
            <p className={styles.banner__info_title}> The Daily Dog </p>
            <p className={styles.banner__info_text}> Happy pup, happy life! Find answers to all your pet questions below.  </p>
          </div>
          <div className={styles.banner__info_handle}>
            <Button
              theme={['white']}
              title='View all posts'
              rightImage='/next-green.svg'
              onClick={() => router.push('/blog')}
              alt='View all posts'
              altImg='View all posts'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogBanner
