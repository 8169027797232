import React, { FC } from 'react'
import Link from 'next/link'

// Components
import BlogBanner from '../blogBanner'
import BlogPost from '../blogPost'
import CategoriesList from '../categoriesList'
import PetList from '../listPets'
import AllLocations from '../allLocations'
// Utils
import styles from './DailyDog.module.scss'
import { DailyDogType } from '@/types/index'

const DailyDog: FC<DailyDogType> = ({
  posts,
  breedersLocation,
  categories,
  breedsExplorer,
  puppyQuiz,
}) => {
  return (
    <div className={styles.section_1}>
      {!puppyQuiz && <BlogBanner />}

      {!puppyQuiz && <BlogPost data={posts} />}
      <section className={styles.section_2}>
        <Link
          href={`${process.env.NEXT_PUBLIC_ROOT_URL}/puppies-for-sale`}
          legacyBehavior
        >
          <h2 className={styles.titleSection}>Browse Our Puppies for Sale</h2>
        </Link>
        <CategoriesList data={categories} />
        <PetList
          breedsExplorer={breedsExplorer}
          more="View all breeds"
          less="View less breeds"
        />
        <AllLocations breedersLocation={breedersLocation} hasSearch={false} />
      </section>
    </div>
  )
}

export default DailyDog
