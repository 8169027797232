import React from 'react'
import Link from 'next/link'
import useMediaQuery from '../../utils/publicApi'
// Utils
import styles from './categoriesList.module.scss'

function CategoryNameConverter(category) {
  const isMobile = useMediaQuery('(max-width:600px)')
  if (category === 'affordable' && isMobile) {
    return 'Affordable puppies...'
  } else {
    switch (category) {
      case 'kid_friendly':
        return 'Family dogs'
      case 'apartment':
        return 'Apartment Dogs'
      case 'affordable':
        return 'Affordable puppies under $1000'
      case 'small':
        return 'Mini & Teacup Dogs'
      case 'emotional_support':
        return 'Emotional Support Dogs'
      case 'hypoallergenic':
        return 'Hypoallergenic Dogs'
      case 'guard':
        return 'Guard dogs'
      case 'hunting':
        return 'Hunting dogs'
      case 'service':
        return 'Service dogs'
      case 'active':
        return 'Active Lifestyle Dogs'
      case 'doodle':
        return 'Doodles'
    }
  }
  return null
}

const CategoriesList = ({ data }) => {
  const allCategories = Object.keys(data || {}).filter(
    (c) => c !== '__typename',
  )

  return (
    <div className={styles.categoriesListContainer}>
      <div className={styles.data}>
        <h2 className={styles.title}>Explore by Category</h2>
        <div className={styles.list}>
          {allCategories.map((category, i) => (
            <div key={i} className={styles.link}>
              <Link href={`puppies-for-sale/${category}`} prefetch={false}>
                <p>{CategoryNameConverter(category)}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CategoriesList
