import React, { memo } from 'react'
import Link from 'next/link'
import Image from 'next/legacy/image'
// Utils
import styles from './blogPost.module.scss'

export const BlogPostCard = memo(({ data }) => {
  return (
    <div className={styles.card__Post}>
      <div className={styles.card__Post_image}>
        <Link href={`/blog/${data.slug}`} prefetch={false} alt={data?.title}>

          {
            data?.metadata?.image?.imgix_url &&
              <Image
                src={data?.metadata?.image?.imgix_url}
                alt={data.title}
                height={170}
                width={254}
                layout='responsive'
                objectFit='cover'
              />
          }

        </Link>
      </div>
      <div className={styles.card__Post_wrapperInfo}>
        <div className={styles.card__Post_title}>
          <Link href={`/blog/${data.slug}`} prefetch={false} alt={data?.title}>
            {data.title}
          </Link>
        </div>
        <div className={styles.card__Post_time}>{data?.metadata?.reading_time} read</div>
        <div className={styles.card__Post_read}>
          <Link href={`/blog/${data.slug}`} prefetch={false} alt={data?.title}>
            Read article
          </Link>
        </div>
      </div>
    </div>
  )
})

export default BlogPostCard
